import { useContext, useEffect } from "react";
import { StoreContext } from "../../../Contexts";
import { mapService } from "../../../services";
import { MapUtils } from "../../../utils";
import { LayoutOption } from "../../../Enums";
import { LayoutContext } from "../../../Contexts/LayoutContext";
import {usePanoramaUtils} from "../../../utils";

const layerId = 'panoImagery';

const PanoramaImageLayer = (
    { map,
        visible,
        layerRef,
        updateLayer }, ...props) => {
    const { Maps } = window.Microsoft;
    const appContext = useContext(StoreContext);
    const layoutContext = useContext(LayoutContext);
    const [activeProject] = appContext.activeProject;
    const [layout] = layoutContext.layout;
    const [imageId, setImageId] = appContext.imageId;
    const { getPanoramas } = usePanoramaUtils();

    const updatePanoramaImages = async () => {
        updateLayer(layerId, null);
        let layer = mapService.initLayer(layerId, map, visible);
        layer.clear();
        let panoImagery = await getPanoramas(activeProject.collection_name, mapService.getMapBounds(map));
        panoImagery.forEach((image) => {
            let color = image.id === imageId ? 'yellow' : 'CornflowerBlue';
            let pushpin = new Maps.Pushpin({
                latitude: image.Latitude_deg,
                longitude: image.Longitude_deg,

            }, {
                icon: MapUtils.getMapIcon(layerId, color, true)
            });
            pushpin.customData = image;
            layer.add(pushpin);

            Maps.Events.addHandler(pushpin, 'click', (e) => {
                setImageId(pushpin.customData.id);
                if (layout === LayoutOption.FullPage) {
                    window.open(`/pano-imagery-one?image_id=${pushpin.customData.id}`,
                        '_blank');
                }
            });
        });
        updateLayer(layerId, layer);
    }

    useEffect(() => {
        const plotPanoramaImages = async () => {
            Maps.Events.addOne(map, 'viewrendered', async () => {
                await updatePanoramaImages();
            });
            Maps.Events.addHandler(map, 'viewchangeend', async function () {
                await updatePanoramaImages();
            });
        }
        if (map) {
            plotPanoramaImages();
        }
    }, [map])

    useEffect(() => {
        if (imageId) {
            updatePanoramaImages();
        }

    }, [imageId])

    useEffect(() => {
        if (layerRef) {
            layerRef.setVisible(visible);
        }
    }, [visible, layerRef])
}

export default PanoramaImageLayer;